import { BACKEND_API, FRONTEND_HOST } from "@/config/constants";
import { apiClient } from "@/helpers/request";
import { ICourseListingPage } from "@/pages/course/interfaces";
import { CLP_CONTENT } from "./clp-content";

export const getCourseWidgets = (query: { studentId?: string; questionId?: string; Exam?: string | string[]; page: string; }) => {
  return apiClient(BACKEND_API, `/v1/web/course-widgets`, {
    query,
    headers: {
      origin: FRONTEND_HOST
    }
  });
}

export const updateCourseLead = (body: { webStudentId: string; assortmentId: string; sourceUrl: string; isLoggedIn: number }) => {
  return apiClient(BACKEND_API, "/v1/web/course-lead", {
    method: "POST",
    body: JSON.stringify(body)
  })
}

export const getCourseDetail = (assortment_id: string): Promise<any> => {
  return apiClient(BACKEND_API, `/v7/course/web-detail`, {
    headers: {
      version_code: "1029",
    },
    query: {
      assortment_id,
    },
  });
};

export const getCoursesByFilter = async (query: {
  [key: string]: string;
}): Promise<{ data: ICourseListingPage, breadcrumbs?: any }> => {

  if (!Object.keys(query).length) {
    const data: any = { ...CLP_CONTENT.find(x => !x.Class && !x.Exam) };
    if (!data.widgets?.length) {
      return {
        data: {
          ...data,
          widgets: [],
        },
      };
    }
    const widgets = await Promise.all(data.widgets.map((x: { assortment_id: string }, i: number) => getCourseDetail(x.assortment_id).then(res => {
      if (!res.data?.extra_widgets) return;
      const info = res.data.extra_widgets.find((x: any) => x.type === "widget_course_info_v2")?.data;
      const pricePlan = res.data.extra_widgets.find((x: any) => x.type === "widget_course_plan")?.data.items[0];
      const widget = data.widgets[i];
      return {
        ...widget,
        title: info.title,
        subtitle: info.subtitle,
        base_price: pricePlan.amount_strike_through.replace("₹", "").replace(",", ""),
        display_price: pricePlan.amount_to_pay.replace("₹", "").replace(",", ""),
      };
    })));
    return {
      data: {
        ...data,
        widgets: widgets.filter(Boolean),
      },
    };
  }

  for (let key in query) {
    query[key] = decodeURIComponent(query[key]);
  }

  const res = await apiClient(BACKEND_API, `/v6/course/get-clp-web`, {
    query,
  });
  return {
    data: {
      ...res.data,
      ...CLP_CONTENT.find(x => x.Exam === encodeURIComponent(query.Exam) && x.Class?.toString() === query.Class),
    },
    breadcrumbs: CLP_CONTENT.find(x => x.Exam === encodeURIComponent(query.Exam) && x.Class?.toString() === query.Class)?.breadcrumbs || null,
  };
};

export const getPurchasedCourses = (): Promise<{
  data: {
    widget_data: {
      title: string;
      items: {
        assortment_id: number;
        title: string;
        sub_title: string;
        progress: string;
        bullet_point: string;
      }[]
    }
  };
}> => {
  return apiClient(BACKEND_API, `/v1/web/my-courses`);
}

export const getBreadcrumbData = (exam: string) => {
  if (!exam) return null;
  const breadcrumbs = CLP_CONTENT.find(x => !x.Class && x.Exam === encodeURIComponent(decodeURIComponent(exam)))?.breadcrumbs || null;
  return breadcrumbs ? JSON.parse(JSON.stringify(breadcrumbs)) : null;
}